






































import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import Vue from 'vue';
import ToastMessage from '@/models/ToastMessage';
import AuthService from '@/services/AuthService';
import { MetaInfo } from 'vue-meta';

const validations = {
  form: {
    email: { required, email },
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  validations: validations,
  components: { ValidatedInput },
  data() {
    return {
      form: {
        email: '',
      },
      loading: false,
      message: '',
      errorMessage: '',
    };
  },
  metaInfo(): MetaInfo {
    return { title: this.$t('pages.auth.ResendConfirmation.meta.title').toString() };
  },
  mounted() {
    this.setBreadCrumb();
  },
  methods: {
    checkErrors(errors: Array<string>) {
      return errors.length == 0;
    },
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.auth.ResendConfirmation.breadcrumb.last' },
      ]);
    },
    handle() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorMessage = this.$t('forms.common.error_message').toString();
        this.$store.commit('addToastMessage', new ToastMessage(this.errorMessage, 'bg-warning'));
      } else {
        this.loading = true;
        this.message = '';
        return AuthService.ResendConfirmation(this.form.email).then(
          () => {
            this.$router.push({ name: 'Login' });
            var toastMessage = this.$t('pages.auth.ResendConfirmation.successToastMessage').toString();
            this.$store.commit('addToastMessage', new ToastMessage(toastMessage, 'bg-success'));
          },
          (error) => {
            this.loading = false;
            this.message = error;
            var toastMessage = this.$t('forms.common.generic_error').toString();
            this.$store.commit('addToastMessage', new ToastMessage(toastMessage, 'bg-warning'));
          }
        );
      }
    },
  },
});
